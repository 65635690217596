import Vue from 'vue'
import Vuex from 'vuex'
import websocket from './modules/websocket'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sendMsg:"",
    token:"",
    BASE_URL:"http://manage.holdlang.com:18329/",
    gradearr:[],
    kemuarr:[],
    kaodianarr:[]
  },
  mutations: {
    savetoken(state,token){
      state.token = token
    },
    savegrade(state,arr){
      state.gradearr = arr
    },
    savekemu(state,arr){
      state.kemuarr = arr
    },
    savekaodian(state,arr){
      state.kaodianarr = arr
    }
  },
  actions: {
    saveGrade({commit},arr){
      commit('savegrade',arr)
    },
    saveKemu({commit},arr){
      commit('savekemu',arr)
    },
    saveKaodian({commit},arr){
      commit('savekaodian',arr)
    },
    sendMessage({state,rootState}, message) {
      message.params["uid"]=rootState.token
      state.sendMsg = JSON.stringify(message)
      if(state.skt.saveMsg['1901'].canlog == 1){
        console.log("发送数据message",state.sendMsg )
      }    
      Vue.prototype.$socket.send(state.sendMsg)          
    }
  },
  modules: {
    skt:websocket
  }
})




